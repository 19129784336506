<template>
	<div>
		<div class="js-copy js-content pd_body" @scroll="handleScroll">
			<topHeader></topHeader>
			<div class="ac_create">
				<div class="ac_create_info">
					<div class="ac_create_info_left">
						<div class="ac_create_info_header">
							<img src="https://zy.metaera.media/assets/images/active/19.png" />
							<span>基本信息</span>
						</div>
						<div class="ac_create_info_upload">
							<!-- 剪裁组件弹窗 -->
							<el-dialog
								title="裁切图片"
								:visible.sync="cropperModel"
								width="1300px"
								center
								:append-to-body="true"
							>
								<template v-if="cropperModel">
									<cropper-image-new
										:Name="cropperName"
										:showType="showType"
										:showAvator="false"
										@uploadImgSuccess="handleUploadSuccess"
										ref="child"
									>
									</cropper-image-new>
								</template>
							</el-dialog>
							<div v-if="pic.length > 0">
								<img
									class="pointer ac_create_info_upload_img"
									@click="uploadImage()"
									:src="pic"
								/>
							</div>
							<div v-else>
								<div class="ac_create_info_upload_space"></div>
								<div class="ac_create_info_upload_add">
									<img
										class="pointer"
										@click="uploadImage()"
										src="https://zy.metaera.media/assets/images/active/30.png"
									/>
								</div>
								<div
									class="ac_create_info_upload_title pointer"
									@click="uploadImage()"
								>
									<img
										src="https://zy.metaera.media/assets/images/active/33.png"
									/>
									<span>上传活动海报</span>
								</div>
								<div class="ac_create_info_upload_tip"> 推荐海报比例 16:9 </div>
							</div>
						</div>
						<div class="ac_create_info_date">
							<div class="ac_create_info_add_header">
								<span>活动时间</span>
							</div>
							<div class="ac_create_info_add_item">
								<div class="ac_create_info_add_item_text"> 开始： </div>
								<div class="ac_create_info_add_item_week">
									<el-date-picker
										v-model="start_day"
										type="date"
										placeholder="请选择开始日期"
									>
									</el-date-picker>
								</div>
								<div class="ac_create_info_add_item_time">
									<el-time-select
										v-model="start_hour"
										:picker-options="{
											start: '00:00',
											step: '00:10',
											end: '23:50',
										}"
										placeholder="请选择开始时间"
									>
									</el-time-select>
								</div>
								<div class="ac_create_info_add_item_gmt">
									<el-dropdown
										@command="timeZoneHandleCommand($event)"
										class="ac_create_ticket_add_type_down"
										placement="bottom-start"
										size="medium"
									>
										<span class="el-dropdown-link">
											{{ timeZone }}
											<img
												src="https://zy.metaera.media/assets/images/active/20.png"
											/>
										</span>

										<el-dropdown-menu
											class="ac_create_ticket_down_menu"
											slot="dropdown"
										>
											<el-dropdown-item
												v-for="(item, index) in timeZones"
												:key="index"
												:command="index"
												class="ac_create_ticket_down_menu_item"
											>
												{{ item.timeZone }}
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>

							<div class="ac_create_info_add_item">
								<div class="ac_create_info_add_item_text"> 结束： </div>
								<div class="ac_create_info_add_item_week">
									<el-date-picker
										v-model="end_day"
										type="date"
										placeholder="请选择结束日期"
									>
									</el-date-picker>
								</div>
								<div class="ac_create_info_add_item_time">
									<el-time-select
										v-model="end_hour"
										:picker-options="{
											start: '00:00',
											step: '00:10',
											end: '23:50',
										}"
										placeholder="请选择结束时间"
									>
									</el-time-select>
								</div>
								<div class="ac_create_info_add_item_gmt">
									<el-dropdown
										@command="timeZoneHandleCommand($event)"
										class="ac_create_ticket_add_type_down"
										placement="bottom-start"
										size="medium"
									>
										<span class="el-dropdown-link">
											{{ timeZone }}
											<img
												src="https://zy.metaera.media/assets/images/active/20.png"
											/>
										</span>

										<el-dropdown-menu
											class="ac_create_ticket_down_menu"
											slot="dropdown"
										>
											<el-dropdown-item
												v-for="(item, index) in timeZones"
												:key="index"
												:command="index"
												class="ac_create_ticket_down_menu_item"
											>
												{{ item.timeZone }}
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>
						</div>
					</div>
					<div class="ac_create_info_right">
						<div class="ac_create_info_add_header">
							<span>活动标题</span>
						</div>
						<div class="ac_create_info_title">
							<el-input
								class="ac_create_input"
								placeholder="请输入活动名称"
								v-model="title"
							>
							</el-input>
						</div>
						<div class="ac_create_info_other">
							<div class="ac_create_info_address">
								<div class="ac_create_info_add_header">
									<span>活动地址</span>
								</div>
								<el-input
									class="ac_create_info_input ac_create_input"
									v-model="address"
									placeholder="请输入地址"
								>
									<template slot="prefix">
										<img
											src="https://zy.metaera.media/assets/images/active/21.png"
											alt="icon"
											class="input-icon"
										/>
									</template>
								</el-input>
							</div>
							<div class="ac_create_info_links">
								<div class="ac_create_info_add_header">
									<span>活动链接</span>
								</div>
								<el-input
									v-model="url"
									placeholder="请输入链接"
									class="ac_create_info_input ac_create_input"
								>
									<template slot="prefix">
										<img
											src="https://zy.metaera.media/assets/images/active/23.png"
											alt="icon"
											class="input-icon"
										/>
									</template>
								</el-input>
							</div>
						</div>
						<div class="ac_create_info_line">
							<div class="ac_create_info_line_on" @click="onLineClick(5)">
								<img
									src="https://zy.metaera.media/assets/images/active/25.png"
									v-if="type == 5"
								/>
								<img
									v-else
									src="https://zy.metaera.media/assets/images/active/24.png"
								/>
								<span>线上活动</span>
							</div>
							<div class="ac_create_info_line_off" @click="onLineClick(6)">
								<img
									src="https://zy.metaera.media/assets/images/active/25.png"
									v-if="type == 6"
								/>
								<img
									v-else
									src="https://zy.metaera.media/assets/images/active/24.png"
								/>
								<span>线下活动</span>
							</div>
						</div>
					</div>
				</div>

				<div class="ac_create_organ_space"></div>
				<div class="ac_create_organ_space"></div>
				<div class="ac_create_info_detail">
					<div class="ac_create_info_header">
						<img src="https://zy.metaera.media/assets/images/active/26.png" />
						<span>活动详情</span>
					</div>

					<div class="ac_create_info_detail_content">
						<div style="border: 1px solid #ccc">
							<Toolbar
								style="border-bottom: 1px solid #ccc"
								:editor="editor"
								:defaultConfig="toolbarConfig"
								:mode="mode"
							/>
							<Editor
								style="height: 500px; overflow-y: hidden"
								v-model="content"
								class="ql-editor"
								:defaultConfig="editorConfig"
								:mode="mode"
								@onCreated="onCreated"
							/>
						</div>
					</div>
				</div>
				<div class="ac_create_organ">
					<div class="ac_create_info_header">
						<img src="https://zy.metaera.media/assets/images/active/26.png" />
						<span>基本资料</span>
					</div>
					<div class="ac_create_organ_space"></div>
					<div class="ac_create_info_add_header">
						<span>主办方</span>
						<img
							src="https://zy.metaera.media/assets/images/active/31.png"
							@click="hostAddClick()"
						/>
					</div>
					<div class="ac_create_organ_people">
						<div
							class="ac_create_organ_people_item"
							v-for="(item, index) in hostsArray"
							:key="index"
						>
							<img
								class="ac_create_organ_people_item_header"
								:src="item.pic"
								@click="hostEditClick(index)"
							/>
							<img
								class="ac_create_organ_people_item_del"
								src="https://zy.metaera.media/assets/images/active/42.png"
								@click="hostDelClick(index)"
							/>
						</div>
						<div class="ac_create_organ_people_line"></div>
						<addPeople @confirmation="addHost" :item="tmpHostItem"></addPeople>
					</div>
					<div class="ac_create_organ_space"></div>
					<div class="ac_create_organ_space"></div>
					<div class="ac_create_info_add_header">
						<span>协办方</span>
						<img
							src="https://zy.metaera.media/assets/images/active/31.png"
							@click="coHostAddClick()"
						/>
					</div>
					<div class="ac_create_organ_people">
						<div
							class="ac_create_organ_people_item"
							v-for="(item, index) in coHostsArray"
							:key="index"
						>
							<img
								class="ac_create_organ_people_item_header"
								:src="item.pic"
								@click="coHostEditClick(index)"
							/>
							<img
								class="ac_create_organ_people_item_del"
								src="https://zy.metaera.media/assets/images/active/42.png"
								@click="coHostDelClick(index)"
							/>
						</div>
						<div class="ac_create_organ_people_line"></div>
						<addPeople @confirmation="addCoHost" :item="tmpCoHostItem">
						</addPeople>
					</div>
				</div>

				<div class="ac_create_ticket">
					<div class="ac_create_info_header ac_create_info_header_between">
						<div class="ac_create_info_header_left">
							<img src="https://zy.metaera.media/assets/images/active/26.png" />
							<span>票务信息</span>
						</div>
					</div>
					<div style="height: 40px"></div>
					<div class="ac_create_info_add_header">
						<span>售票时间</span>
					</div>
					<div class="ac_create_info_date_sale">
						<div class="ac_create_info_add_item">
							<div class="ac_create_info_add_item_text"> 开始： </div>
							<div class="ac_create_info_add_item_week">
								<el-date-picker
									v-model="sale_start_day"
									type="date"
									placeholder="请选择开始日期"
								>
								</el-date-picker>
							</div>
							<div class="ac_create_info_add_item_time">
								<el-time-select
									v-model="sale_start_hour"
									:picker-options="{
										start: '00:00',
										step: '00:10',
										end: '23:50',
									}"
									placeholder="请选择开始时间"
								>
								</el-time-select>
							</div>
							<div class="ac_create_info_add_item_gmt">
								<el-dropdown
									@command="saleTimeZoneHandleCommand($event)"
									class="ac_create_ticket_add_type_down"
									placement="bottom-start"
									size="medium"
								>
									<span class="el-dropdown-link">
										{{ sale_timeZone }}
										<img
											src="https://zy.metaera.media/assets/images/active/20.png"
										/>
									</span>

									<el-dropdown-menu
										class="ac_create_ticket_down_menu"
										slot="dropdown"
									>
										<el-dropdown-item
											v-for="(item, index) in timeZones"
											:key="index"
											:command="index"
											class="ac_create_ticket_down_menu_item"
										>
											{{ item.timeZone }}
										</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>
						<div style="width: 50px"></div>
						<div class="ac_create_info_add_item">
							<div class="ac_create_info_add_item_text"> 结束： </div>
							<div class="ac_create_info_add_item_week">
								<el-date-picker
									v-model="sale_end_day"
									type="date"
									placeholder="请选择结束日期"
								>
								</el-date-picker>
							</div>
							<div class="ac_create_info_add_item_time">
								<el-time-select
									v-model="sale_end_hour"
									:picker-options="{
										start: '00:00',
										step: '00:10',
										end: '23:50',
									}"
									placeholder="请选择结束时间"
								>
								</el-time-select>
							</div>
							<div class="ac_create_info_add_item_gmt">
								<el-dropdown
									@command="saleTimeZoneHandleCommand($event)"
									class="ac_create_ticket_add_type_down"
									placement="bottom-start"
									size="medium"
								>
									<span class="el-dropdown-link">
										{{ sale_timeZone }}
										<img
											src="https://zy.metaera.media/assets/images/active/20.png"
										/>
									</span>

									<el-dropdown-menu
										class="ac_create_ticket_down_menu"
										slot="dropdown"
									>
										<el-dropdown-item
											v-for="(item, index) in timeZones"
											:key="index"
											:command="index"
											class="ac_create_ticket_down_menu_item"
										>
											{{ item.timeZone }}
										</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>
					</div>

					<div style="height: 50px"></div>
					<div class="ac_create_info_add_header">
						<span>票务信息</span>
						<img
							src="https://zy.metaera.media/assets/images/active/31.png"
							@click="ticketAddClick()"
						/>
					</div>
					<div class="ac_create_ticket_header">
						<div class="ac_create_ticket_header_1">票名</div>
						<div class="ac_create_ticket_header_2">票类型</div>
						<div class="ac_create_ticket_header_3">邀请码</div>
						<div class="ac_create_ticket_header_4">价格（单张）</div>
						<div class="ac_create_ticket_header_6">币种</div>
						<div class="ac_create_ticket_header_5">数量</div>
					</div>
					<div
						class="ac_create_ticket_add"
						v-for="(item, index) in ticketArray"
						:key="index"
					>
						<div class="ac_create_ticket_add_name">
							<el-input
								class="ac_create_input"
								placeholder="请输入活动名称"
								v-model="item.name"
							>
							</el-input>
						</div>
						<div class="ac_create_ticket_add_type">
							<el-dropdown
								@command="ticketHandleCommand(index, $event)"
								class="ac_create_ticket_add_type_down"
								placement="bottom-start"
								size="medium"
							>
								<span class="el-dropdown-link">
									{{
										item.type == 999 || item.type == ""
											? localLanguage == 2
												? "Official ticket"
												: "官方票"
											: item.type == 0
											? localLanguage == 2
												? "Free ticket"
												: "免费票"
											: item.type == 1
											? localLanguage == 2
												? "Discount ticket"
												: "折扣票"
											: localLanguage == 2
											? "Ordinary ticket"
											: "普通票"
									}}
									<img
										src="https://zy.metaera.media/assets/images/active/20.png"
									/>
								</span>

								<el-dropdown-menu
									class="ac_create_ticket_down_menu"
									slot="dropdown"
								>
									<el-dropdown-item
										command="0"
										class="ac_create_ticket_down_menu_item"
									>
										免费票
									</el-dropdown-item>
									<el-dropdown-item
										command="1"
										class="ac_create_ticket_down_menu_item"
									>
										折扣票
									</el-dropdown-item>
									<el-dropdown-item
										command="2"
										class="ac_create_ticket_down_menu_item"
									>
										普通票
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="ac_create_ticket_add_code">
							<el-input
								class="ac_create_input"
								placeholder="请输入活动邀请码"
								:disabled="item.type == 0 || item.type == 2"
								v-model="item.code"
							>
							</el-input>
						</div>
						<div class="ac_create_ticket_add_price">
							<el-input
								class="ac_create_input"
								placeholder="请输入活动价格"
								:disabled="item.type == 0"
								v-model="item.price"
								type="number"
								min="0"
							>
							</el-input>
						</div>
						<div class="ac_create_ticket_add_currency">
							<el-dropdown
								@command="currencyHandleCommand(index, $event)"
								class="ac_create_ticket_add_type_down"
								placement="bottom-start"
								size="medium"
							>
								<span class="el-dropdown-link">
									{{
										item.currency == "" || item.currency == undefined
											? "USD"
											: item.currency
									}}
									<img
										src="https://zy.metaera.media/assets/images/active/20.png"
									/>
								</span>

								<el-dropdown-menu
									class="ac_create_ticket_down_menu"
									slot="dropdown"
								>
									<el-dropdown-item
										v-for="(item, index) in currencys"
										:key="index"
										:command="index"
										class="ac_create_ticket_down_menu_item"
									>
										{{ item }}
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="ac_create_ticket_add_num">
							<el-input
								class="ac_create_input"
								placeholder="请输入活动数量"
								v-model="item.num"
								type="number"
								min="1"
								@keypress="onlyAllowNumbers"
							>
							</el-input>
						</div>
						<div
							class="ac_create_ticket_add_num_del"
							@click="ticketDelClick(index)"
						>
							<img
								v-if="index > 0"
								src="https://zy.metaera.media/assets/images/active/42.png"
							/>
						</div>
					</div>
				</div>

				<div class="ac_create_bottom">
					<acSuccess
						v-if="showSuccess"
						@showNext="showNext()"
						:tipTitle="tipTitle"
						:tipMessage="tipMessage"
					>
					</acSuccess>
					<div class="ac_create_bottom_btn0" @click="goBack()">上一步</div>
					<div class="ac_create_bottom_btn1" @click="acitvityEdit(0)">
						草稿箱
					</div>
					<div class="ac_create_bottom_btn2" @click="acitvityEdit(3)">
						确认
					</div>
				</div>
			</div>
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
import { acitvityEdit } from "@/api/home";
import { acitvityDetail } from "@/api/home";
import topHeader from "../../components/pc/topHeader.vue";
import bottom from "../../components/pc/bottom.vue";
import addPeople from "./ac_people.vue";
import acSuccess from "./ac_success.vue";
import { getUserId } from "@/utils/auth";
import { eventBus } from "../../components/pc/leftTab";
import { getToken } from "@/utils/auth";
import Vue from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";
export default {
	name: "",
	components: {
		topHeader,
		bottom,
		addPeople,
		acSuccess,
		Editor,
		Toolbar,
	},
	data() {
		return {
			editor: null,
			toolbarConfig: {
				excludeKeys: ["group-video", "fullScreen", "lineHeight", "fontFamily"], // 排除视频菜单
			},
			editorConfig: {
				placeholder: "请输入内容...",
				MENU_CONF: {
					uploadImage: {
						server: "https://api.metaera.media/upload", // 上传图片的服务器地址
						fieldName: "file", // 上传文件的字段名
						maxFileSize: 8 * 1024 * 1024, // 最大文件大小，默认2M
						maxNumberOfFiles: 5, // 最多可上传文件数量
						allowedFileTypes: ["image/*"], // 允许的文件类型
						onSuccess(file, res) {
							console.log("上传成功", file, res);
						},
						onFailed(file, res) {
							console.log("上传失败", file, res);
						},
						onError(file, err, res) {
							console.log("上传错误", file, err, res);
						},
						customInsert(res, insertFn) {
							// 解析自定义响应格式
							if (res.code === 200 && res.data && res.data.url) {
								const url = res.data.url;
								insertFn(url);
							} else {
								console.error("上传失败，服务器返回数据格式错误", res);
							}
						},
					}, // 自定义字号选项
					fontSize: {
						fontSizeList: [
							"12px",
							"14px",
							"16px",
							"18px",
							"20px",
							"24px",
							"28px",
							"32px",
							"36px",
							"48px",
						],
					}, // 设置默认内容样式
				},
			},
			mode: "default",
			showSuccess: false,
			loading: false,
			cropperModel: false,
			cropperName: "",
			showType: 0,
			localLanguage: 0,
			title: "",
			tipTitle: "Event application is successful",
			tipMessage:
				"Please wait 1~3 working days, the event will be posted on the event page after approval",
			pic: "",
			start_time: "",
			start_day: new Date(),
			start_hour: "09:00",
			end_time: "",
			end_day: new Date(),
			end_hour: "18:00",

			sale_start_time: "",
			sale_start_day: new Date(),
			sale_start_hour: "00:00",
			sale_end_time: "",
			sale_end_day: new Date(),
			sale_end_hour: "23:50",
			sale_timeZone: "UTC+08:00",

			num: "",
			id: "",
			isfree: "",
			content: "",
			address: "",
			type: "5",
			address: "",
			price: "",
			url: "",
			currencys: ["SGD", "USD", "CNY", "HKD", "EUR", "AED"],
			timeZone: "UTC+08:00",
			timeZones: [
				{
					timeZone: "UTC-12:00",
					timeZoneName: "International Date Line West",
					ianaName: "Etc/GMT+12",
				},
				{
					timeZone: "UTC-11:00",
					timeZoneName: "Coordinated Universal Time-11",
					ianaName: "Etc/GMT+11",
				},
				{
					timeZone: "UTC-10:00",
					timeZoneName: "Hawaii-Aleutian Standard Time (HST)",
					ianaName: "Pacific/Honolulu",
				},
				{
					timeZone: "UTC-09:30",
					timeZoneName: "Marquesas Islands Time",
					ianaName: "Pacific/Marquesas",
				},
				{
					timeZone: "UTC-09:00",
					timeZoneName: "Alaska Standard Time (AKST)",
					ianaName: "America/Anchorage",
				},
				{
					timeZone: "UTC-08:00",
					timeZoneName: "Pacific Standard Time (PST)",
					ianaName: "America/Los_Angeles",
				},
				{
					timeZone: "UTC-07:00",
					timeZoneName: "Mountain Standard Time (MST)",
					ianaName: "America/Denver",
				},
				{
					timeZone: "UTC-06:00",
					timeZoneName: "Central Standard Time (CST)",
					ianaName: "America/Chicago",
				},
				{
					timeZone: "UTC-05:00",
					timeZoneName: "Eastern Standard Time (EST)",
					ianaName: "America/New_York",
				},
				{
					timeZone: "UTC-04:00",
					timeZoneName: "Atlantic Standard Time (AST)",
					ianaName: "America/Halifax",
				},
				{
					timeZone: "UTC-03:30",
					timeZoneName: "Newfoundland Standard Time (NST)",
					ianaName: "America/St_Johns",
				},
				{
					timeZone: "UTC-03:00",
					timeZoneName: "Argentina Time (ART)",
					ianaName: "America/Argentina/Buenos_Aires",
				},
				{
					timeZone: "UTC-02:00",
					timeZoneName: "Coordinated Universal Time-2",
					ianaName: "Etc/GMT+2",
				},
				{
					timeZone: "UTC-01:00",
					timeZoneName: "Azores Standard Time (AZOT)",
					ianaName: "Atlantic/Azores",
				},
				{
					timeZone: "UTC±00:00",
					timeZoneName: "Greenwich Mean Time (GMT)",
					ianaName: "Europe/London",
				},
				{
					timeZone: "UTC+01:00",
					timeZoneName: "Central European Time (CET)",
					ianaName: "Europe/Berlin",
				},
				{
					timeZone: "UTC+02:00",
					timeZoneName: "Eastern European Time (EET)",
					ianaName: "Europe/Kiev",
				},
				{
					timeZone: "UTC+03:00",
					timeZoneName: "Moscow Standard Time (MSK)",
					ianaName: "Europe/Moscow",
				},
				{
					timeZone: "UTC+03:30",
					timeZoneName: "Iran Standard Time (IRST)",
					ianaName: "Asia/Tehran",
				},
				{
					timeZone: "UTC+04:00",
					timeZoneName: "Gulf Standard Time (GST)",
					ianaName: "Asia/Dubai",
				},
				{
					timeZone: "UTC+04:30",
					timeZoneName: "Afghanistan Time (AFT)",
					ianaName: "Asia/Kabul",
				},
				{
					timeZone: "UTC+05:00",
					timeZoneName: "Pakistan Standard Time (PKT)",
					ianaName: "Asia/Karachi",
				},
				{
					timeZone: "UTC+05:30",
					timeZoneName: "India Standard Time (IST)",
					ianaName: "Asia/Kolkata",
				},
				{
					timeZone: "UTC+05:45",
					timeZoneName: "Nepal Time (NPT)",
					ianaName: "Asia/Kathmandu",
				},
				{
					timeZone: "UTC+06:00",
					timeZoneName: "Bangladesh Standard Time (BST)",
					ianaName: "Asia/Dhaka",
				},
				{
					timeZone: "UTC+06:30",
					timeZoneName: "Cocos Islands Time (CCT)",
					ianaName: "Indian/Cocos",
				},
				{
					timeZone: "UTC+07:00",
					timeZoneName: "Indochina Time (ICT)",
					ianaName: "Asia/Bangkok",
				},
				{
					timeZone: "UTC+08:00",
					timeZoneName: "China Standard Time (CST)",
					ianaName: "Asia/Shanghai",
				},
				{
					timeZone: "UTC+08:45",
					timeZoneName: "Central Western Standard Time (CWST)",
					ianaName: "Australia/Eucla",
				},
				{
					timeZone: "UTC+09:00",
					timeZoneName: "Japan Standard Time (JST)",
					ianaName: "Asia/Tokyo",
				},
				{
					timeZone: "UTC+09:30",
					timeZoneName: "Australian Central Standard Time (ACST)",
					ianaName: "Australia/Adelaide",
				},
				{
					timeZone: "UTC+10:00",
					timeZoneName: "Australian Eastern Standard Time (AEST)",
					ianaName: "Australia/Sydney",
				},
				{
					timeZone: "UTC+10:30",
					timeZoneName: "Lord Howe Standard Time (LHST)",
					ianaName: "Australia/Lord_Howe",
				},
				{
					timeZone: "UTC+11:00",
					timeZoneName: "Solomon Islands Time (SBT)",
					ianaName: "Pacific/Guadalcanal",
				},
				{
					timeZone: "UTC+12:00",
					timeZoneName: "New Zealand Standard Time (NZST)",
					ianaName: "Pacific/Auckland",
				},
				{
					timeZone: "UTC+12:45",
					timeZoneName: "Chatham Islands Time (CHAST)",
					ianaName: "Pacific/Chatham",
				},
				{
					timeZone: "UTC+13:00",
					timeZoneName: "Tonga Time (TOT)",
					ianaName: "Pacific/Tongatapu",
				},
				{
					timeZone: "UTC+14:00",
					timeZoneName: "Line Islands Time (LINT)",
					ianaName: "Pacific/Kiritimati",
				},
			],
			userid: getUserId(),
			tmpHostItem: {
				index: 9999,
				pic: "",
				name: "",
				content: "",
				insLink: "",
				twLink: "",
				ytLink: "",
				tgLink: "",
			},
			tmpCoHostItem: {
				index: 9999,
				pic: "",
				name: "",
				content: "",
				insLink: "",
				twLink: "",
				ytLink: "",
				tgLink: "",
			},
			hostsArray: [],
			coHostsArray: [],
			ticketArray: [
				{
					name: "",
					type: 2,
					code: "",
					price: "",
					currency: "USD",
					num: "10",
				},
			],
		};
	},
	created() {
		this.id = this.$route.query.id;
		if (this.id && this.id > 0) {
			this.getAcitvityDetail();
		}
	},
	mounted() {
		this.token = getToken();
		if (this.token == 0 || this.token == undefined) {
			this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			this.$message({
				message: "Please log in first, otherwise the edits will be lost",
				type: "error",
			});
			return;
		}
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	beforeDestroy() {
		const editor = this.editor;
		if (editor == null) return;
		editor.destroy(); // 组件销毁时，及时销毁编辑器
	},
	methods: {
		onCreated(editor) {
			this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
		},

		uploadImage() {
			this.showType = 1;
			this.cropperName = this.pic;
			this.cropperModel = true;
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			// console.log(data.url);
			this.cropperModel = false;
			this.pic = data.url;
		},
		goBack() {
			if (window.history.length > 1) {
				this.$router.go(-1);
			} else {
				this.$router.push("/");
			}
		},
		onlyAllowNumbers(event) {
			console.log(event);

			const charCode = event.charCode;
			if (charCode < 49 || charCode > 57) {
				// 只允许输入 1-9
				event.preventDefault();
			}
		},
		getAcitvityDetail() {
			this.$loading.show();
			let obj = { id: this.id };
			acitvityDetail(obj)
				.then((res) => {
					this.$loading.hide();
					if (res.code == 200) {
						Object.assign(this.$data, res.data);
						this.ticketArray = JSON.parse(res.data.ticket);
						this.hostsArray = JSON.parse(res.data.organizer);
						this.coHostsArray = JSON.parse(res.data.host);

						this.start_day = new Date(res.data.start_time);
						if (res.data.end_time == "0000-00-00 00:00:00") {
							this.end_day = new Date(
								this.start_day.getFullYear(),
								this.start_day.getMonth(),
								this.start_day.getDate() + 1
							);
						} else {
							this.end_day = new Date(res.data.end_time);
						}

						this.start_hour =
							this.start_day.getHours() + ":" + this.start_day.getMinutes();
						this.end_hour =
							this.end_day.getHours() + ":" + this.end_day.getMinutes();

						this.sale_start_day = new Date(res.data.sale_start_time);
						this.sale_end_day = new Date(res.data.sale_end_time);
						if (res.data.sale_start_time == "0000-00-00 00:00:00") {
							let tmpDate = new Date();
							this.sale_start_time = new Date(
								tmpDate.getFullYear(),
								tmpDate.getMonth(),
								tmpDate.getDate()
							);
						}
						if (res.data.sale_end_time == "0000-00-00 00:00:00") {
							this.sale_end_day = this.start_day;
						}

						this.sale_start_hour =
							this.sale_start_day.getHours() +
							":" +
							this.sale_start_day.getMinutes();
						this.sale_end_hour =
							this.sale_end_day.getHours() +
							":" +
							this.sale_end_day.getMinutes();
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					this.$loading.show();
					this.$message.error(error.message);
				});
		},
		showNext() {
			this.showSuccess = false;
			this.$router.push({
				path: "/ac_organ",
			});
		},
		handleScroll(event) {},
		showUrl(url) {
			window.open(url, "");
		},
		timeZoneHandleCommand(command) {
			let timeItem = this.timeZones[command];
			this.timeZone = timeItem.timeZone;
			console.log("timeItem");
			console.log(timeItem);
		},
		saleTimeZoneHandleCommand(command) {
			let timeItem = this.timeZones[command];
			this.sale_timeZone = timeItem.timeZone;
			console.log("timeItem");
			console.log(timeItem);
		},
		ticketHandleCommand(index, command) {
			let ticketItem = this.ticketArray[index];
			let type = command;
			ticketItem.type = type;
			if (type == 0) {
				ticketItem.code = "";
				ticketItem.price = "";
			} else if (type == 2) {
				ticketItem.code = "";
			}
			console.log(this.ticketArray);
		},
		currencyHandleCommand(index, command) {
			let currency = this.currencys[command];
			// 使用 Vue.set 确保响应式更新
			this.$set(this.ticketArray, index, {
				...this.ticketArray[index],
				currency: currency,
			});
			console.log(this.ticketArray);
		},
		ticketAddClick() {
			// 创建一个新的对象
			let newTicket = {
				name: "",
				type: 2,
				code: "",
				price: "",
				num: "10",
			};

			// 将新对象添加到数组中
			this.ticketArray.push(newTicket);
		},

		ticketDelClick(index) {
			this.ticketArray.splice(index, 1);
		},

		hostAddClick() {
			// 创建一个新的对象
			this.tmpHostItem = {
				index: 9999,
				pic: "",
				name: "",
				content: "",
				insLink: "",
				twLink: "",
				ytLink: "",
				tgLink: "",
			};
		},

		addHost(data) {
			// 将新对象添加到数组中
			console.log(data);
			const index = data.index;
			if (index > 1000) {
				data.index = this.hostsArray.length;
				this.hostsArray.push(data);
			} else {
				this.$set(this.hostsArray, index, data);
			}
			this.tmpHostItem = {
				index: 9999,
				pic: "",
				name: "",
				content: "",
				insLink: "",
				twLink: "",
				ytLink: "",
				tgLink: "",
			};
		},
		hostEditClick(index) {
			this.tmpHostItem = this.hostsArray[index];
		},
		hostDelClick(index) {
			this.hostsArray.splice(index, 1);
		},
		coHostAddClick() {
			// 创建一个新的对象
			this.tmpCoHostItem = {
				index: 9999,
				pic: "",
				name: "",
				content: "",
				insLink: "",
				twLink: "",
				ytLink: "",
				tgLink: "",
			};
		},
		coHostDelClick(index) {
			this.coHostsArray.splice(index, 1);
		},

		coHostEditClick(index) {
			this.tmpCoHostItem = this.coHostsArray[index];
		},
		addCoHost(data) {
			const index = data.index;
			if (index > 1000) {
				data.index = this.coHostsArray.length;
				this.coHostsArray.push(data);
			} else {
				this.$set(this.coHostsArray, index, data);
			}
			this.tmpCoHostItem = {
				index: 9999,
				pic: "",
				name: "",
				content: "",
				insLink: "",
				twLink: "",
				ytLink: "",
				tgLink: "",
			};
		},
		acitvityEdit(status) {
			this.token = getToken();
			if (this.token == 0 || this.token == undefined) {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
				return;
			}

			if (this.title.length == 0) {
				this.$message({ message: "请填写活动名称", type: "error" });
				return;
			}
			if (status == 3) {
				if (this.pic.length == 0) {
					this.$message({ message: "请上传活动图片", type: "error" });
					return;
				}

				if (this.content.length == 0) {
					this.$message({ message: "请编辑活动内容", type: "error" });
					return;
				}

				if (this.address.length == 0) {
					this.$message({ message: "请编辑活动地址", type: "error" });
					return;
				}

				if (this.hostsArray.length == 0) {
					this.$message({ message: "请填写主办方信息", type: "error" });
					return;
				}
				for (let index = 0; index < this.ticketArray.length; index++) {
					const element = this.ticketArray[index];
					if (element.type == 999) {
						this.$message({ message: "请选择票务类型", type: "error" });
						return;
					} else {
						if (element.num == "" || element.num == 0) {
							this.$message({ message: "请选择票务数量", type: "error" });
							return;
						} else if (element.name == "") {
							this.$message({ message: "请选择票务名称", type: "error" });
							return;
						} else {
							if (
								element.type == 2 &&
								(element.price == 0 || element.price == "")
							) {
								this.$message({
									message: "全价票请输入票务价格",
									type: "error",
								});
								return;
							} else if (element.type == 1 && element.code == "") {
								this.$message({
									message: "折扣票请输入票务折扣码",
									type: "error",
								});
								return;
							}
						}
					}
				}
			}
			this.$loading.show();
			this.loading = true;
			let obj = {
				id: this.id,
				title: this.title,
				pic: this.pic,
				content: this.content,
				type: this.type,
				address: this.address,
				timeZone: this.timeZone,
				url: this.url,
				userid: this.userid,
				status: status,
				sale_timeZone: this.sale_timeZone,
				sale_start_time:
					this.sale_start_day.getFullYear() +
					"-" +
					(this.sale_start_day.getMonth() + 1) +
					"-" +
					this.sale_start_day.getDate() +
					" " +
					this.sale_start_hour.split(":")[0] +
					":" +
					this.sale_start_hour.split(":")[1],
				sale_end_time:
					this.sale_end_day.getFullYear() +
					"-" +
					(this.sale_end_day.getMonth() + 1) +
					"-" +
					this.sale_end_day.getDate() +
					" " +
					this.sale_end_hour.split(":")[0] +
					":" +
					this.sale_end_hour.split(":")[1],
				start_time:
					this.start_day.getFullYear() +
					"-" +
					(this.start_day.getMonth() + 1) +
					"-" +
					this.start_day.getDate() +
					" " +
					this.start_hour.split(":")[0] +
					":" +
					this.start_hour.split(":")[1],
				end_time:
					this.end_day.getFullYear() +
					"-" +
					(this.end_day.getMonth() + 1) +
					"-" +
					this.end_day.getDate() +
					" " +
					this.end_hour.split(":")[0] +
					":" +
					this.end_hour.split(":")[1],
				ticket: JSON.stringify(this.ticketArray),
				organizer: JSON.stringify(this.hostsArray),
				host: JSON.stringify(this.coHostsArray),
			};

			acitvityEdit(obj)
				.then((res) => {
					this.$loading.hide();
					this.loading = false;
					if (res.code == 200) {
						this.showSuccess = true;
					} else {
						this.$message({ message: res.message, type: "error" });
					}
				})
				.catch((error) => {
					this.$loading.hide();
					this.loading = false;
					this.$message({ message: error.message, type: "error" });
				});
		},
		onLineClick(type) {
			this.type = type;
		},
	},
};
</script>

<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: #ededed;
}
</style>
<!-- ac_create -->
<style scoped>
.ac_create {
	/* padding-top: 120px; */
	margin-left: 3%;
	margin-right: 3%;
	padding-left: 3%;
	padding-right: 3%;
	margin-top: 170px;
	padding-bottom: 60px;
	margin-bottom: 60px;
	border-radius: 24px;
	opacity: 1;
	background: #ffffff;
}
</style>

<!-- ac_create_info和left -->
<style scoped>
.ac_create_info {
	padding-top: 40px;
	display: flex;
}

.ac_create_info_left {
	width: 50%;
}
.ac_create_info_header {
	display: flex;
}
.ac_create_info_header_between {
	display: flex;
	justify-content: space-between;
}
.ac_create_info_header_left {
	display: flex;
}
.ac_create_info_header img {
	width: 24px;
	height: 24px;
	margin-right: 10px;
}

.ac_create_info_header span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 22px;
	font-weight: 500;
	line-height: 22px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}

.ac_create_info_upload {
	margin-top: 20px;
	/* width: 50%; */
	aspect-ratio: 16 / 9;
	border-radius: 10px;
	opacity: 1;
	background: #f0f0f0;
	text-align: center;
}

.ac_create_info_upload_img {
	width: 100% !important;
	height: 100% !important;
	border-radius: 10px;
}

.ac_create_info_upload_space {
	padding-top: 20%;
}

.ac_create_info_upload_add img {
	width: 16%;
	height: auto;
}

.ac_create_info_upload_title {
	margin-top: 37px;
	align-content: center;
	display: inline-flex;
	box-sizing: border-box;
}

.ac_create_info_upload_title img {
	width: 22px;
	height: 22px;
	margin-right: 12px;
}

.ac_create_info_upload_title span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: normal;
	line-height: 20px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.ac_create_info_upload_tip {
	margin-top: 27px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 描边色/描边色辅助 */
	color: #aaaaaa;
}

.ac_create_info_date {
	margin-top: 28px;
}

.ac_create_info_add_header {
	display: flex;
	justify-content: space-between;
}

.ac_create_info_add_header span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 500;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
.ac_create_info_add_header img {
	width: 22px;
	height: 22px;
}

.ac_create_info_add_item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	margin-top: 15px;
}
.ac_create_info_add_item_text {
	width: 10%;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(16px, calc(100vw * 14 / 1280), 16px);
	font-weight: normal;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}

.ac_create_info_add_item_week {
	/* width: 150px; */
	width: 30%;
	display: flex;
	border-radius: 6px;
	opacity: 1;
	align-items: center;
	height: 50px;
	background: #f6f6f6;
	justify-content: space-between;
}
.ac_create_info_add_item_time {
	width: 30%;
	display: flex;
	justify-content: space-between;
}
.ac_create_info_add_item_gmt {
	/* width: 120px; */
	width: 20%;
	display: flex;
	border-radius: 6px;
	opacity: 1;
	height: 50px;
	align-items: center;
	background: #f6f6f6;
	justify-content: space-between;
	border: 1px solid #333333;
}

.ac_create_info_add_item_del {
	width: 26px;
	height: 26px;
	opacity: 1;
}

.ac_create_info_add_item_del img {
	width: 100% !important;
	height: auto !important;
}

.ac_create_info_add_item span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 描边色/描边色辅助 */
	color: #333333;

	margin-left: 10px;
}

.ac_create_info_add_item img {
	margin-right: 10px;
	width: 8px;
	height: 8px;
}
</style>

<!-- ac_create_info_right -->
<style scoped>
.ac_create_info_right {
	width: 46%;
	margin-left: 4%;
	margin-top: 50px;
}

.ac_create_info_title {
	margin-top: 15px;
	margin-bottom: 30px;
}

.ac_create_info_other {
	display: flex;
	justify-content: space-between;
}

.ac_create_info_address {
	width: 45%;
}

.ac_create_info_links {
	width: 45%;
}

/* 自定义 placeholder 样式 */
.ac_create_input ::-webkit-input-placeholder {
	color: red; /* 设置占位符文字颜色 */
	font-style: italic; /* 设置占位符文字样式为斜体 */
	font-size: 16px; /* 设置占位符文字大小 */
}

.ac_create_input :-ms-input-placeholder {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: red;
	font-style: italic;
	font-size: 16px;
}

.ac_create_input ::-ms-input-placeholder {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: red;
	font-style: italic;
	font-size: 16px;
}

.ac_create_input ::placeholder {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	color: red;
	font-style: italic;
	font-size: 16px;
}

.ac_create_info_input {
	margin-top: 15px;
	align-items: center;
	align-content: center;
	height: 50px;
	border-radius: 6px;
	opacity: 1;
	background: #ffffff;
	border: 1px solid #bfbfbf;
}

.ac_create_info_line {
	margin-top: 22px;
	display: flex;
}

.ac_create_info_line_on,
.ac_create_info_line_off {
	align-items: center;
	display: flex;
	margin-right: 40px;
}
.ac_create_info_line img {
	margin-right: 10px;
	width: 15px;
	height: 15px;
}
.ac_create_info_line span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 18px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}
</style>

<!-- ac_create_info_detail -->
<style scoped>
.ac_create_info_detail {
	margin-top: 15px;
	margin-bottom: 30px;
	/* height: 300px; */
	width: 100%;
}
.ac_create_info_detail_content {
	margin-top: 20px;
	height: 400px;
}
.ql-editor {
	height: 100%;
}
</style>
<!-- ac_create_organ -->
<style scoped>
.ac_create_organ {
	margin-top: 210px;
}
.ac_create_organ_space {
	height: 20px;
}
.ac_create_organ_people {
	margin-top: 32px;
	display: flex;
	flex-wrap: wrap; /* 允许子元素换行 */
	gap: 40px; /* 设置子元素之间的间距 */
}
.ac_create_organ_people_item {
	position: relative;
	width: 120px;
	height: 120px;
}
.ac_create_organ_people_item_header {
	border-radius: 60px;
	width: 100%;
	height: 100%;
	object-fit: cover; /* 保持图像的宽高比并裁剪以填充容器 */
	position: absolute;
	top: 0;
	left: 0;
}

.ac_create_organ_people_item_del {
	width: 34px !important;
	height: 34px !important;
	opacity: 1;
	position: absolute;
	bottom: 0;
	right: 0;
}

.ac_create_organ_people_line {
	height: 1px;
	width: 100%;
	border-radius: 20px;
	opacity: 0.2;
	/* 333 */
	background: #333333;
}
</style>

<!-- ac_create_ticket -->
<style scoped>
.ac_create_ticket {
	margin-top: 30px;
}
.ac_create_ticket_header {
	margin-top: 30px;
	height: 60px;
	border-radius: 10px;
	background: rgba(240, 240, 240, 0.5);
	display: flex;
	align-items: center;
}

.ac_create_info_date_sale {
	display: flex;
}

.ac_create_ticket_header_1,
.ac_create_ticket_header_2,
.ac_create_ticket_header_3,
.ac_create_ticket_header_4 {
	width: 20%;
	text-align: center;
	align-content: center;
}
.ac_create_ticket_header_5,
.ac_create_ticket_header_6 {
	width: 10%;
	text-align: center;
	align-content: center;
}
.ac_create_ticket_add {
	display: flex;
	margin-top: 20px;
	align-items: center;
}

.ac_create_ticket_add span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 22px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.ac_create_ticket_add_name {
	text-align: center;
	align-content: center;
	width: 18%;
	margin-left: 1%;
	margin-right: 1%;
}
.ac_create_ticket_add_currency,
.ac_create_ticket_add_type {
	text-align: center;
	align-content: center;
	padding-left: 1%;
	padding-right: 1%;
	margin-left: 1%;
	margin-right: 1%;
	height: 50px;
	border-radius: 10px;
	opacity: 1;
	border: 1px solid #333333;
}
.ac_create_ticket_add_type {
	width: 17%;
}

.ac_create_ticket_add_type_down {
	width: 100%;
	height: 50px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ac_create_ticket_add_type_down span {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.ac_create_ticket_down_menu {
	width: 160px;
	max-height: 200px; /* 根据需要调整 */
	overflow-y: auto;
}

.ac_create_ticket_down_menu_item {
	height: 40px;
	/* text-align: center; */
	align-content: center;
	align-items: center;
}

.ac_create_ticket_add_code {
	text-align: center;
	align-content: center;
	width: 18%;
	margin-left: 1%;
	margin-right: 1%;
}
.ac_create_ticket_add_price {
	text-align: center;
	align-content: center;
	width: 18%;
	margin-left: 1%;
	margin-right: 1%;
}
.ac_create_ticket_add_currency {
	width: 8%;
}

.ac_create_ticket_add_num {
	text-align: center;
	align-content: center;
	width: 6%;
	margin-left: 1%;
}

.ac_create_ticket_add_num_del {
	width: 34px;
	height: 34px;
	opacity: 1;
}
.ac_create_ticket_add_num_del img {
	width: 100%;
	height: 100%;
}
</style>

<!-- ac_create_bottom -->
<style scoped>
.ac_create_bottom {
	margin-top: 190px;
	margin-bottom: 36px;
	display: flex;
	justify-content: center;
}
.ac_create_bottom_btn0,
.ac_create_bottom_btn1,
.ac_create_bottom_btn2 {
	width: 220px;
	height: 54px;
	border-radius: 10px;
	margin-left: 20px;
	margin-right: 20px;
	opacity: 1;
	display: inline-block;
	/* 主要色/主要色 */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.ac_create_bottom_btn0 {
	color: #333333;
	border: 1px solid #000000;
	background: #ffffff;
}

.ac_create_bottom_btn1 {
	background: #e0eaff;
	border: 1px solid #e0eaff;
	color: #0256ff;
}

.ac_create_bottom_btn2 {
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	color: #ffffff;
}
</style>

<style>
.input-icon {
	width: 26px; /* 根据需要调整图标大小 */
	height: 26px; /* 根据需要调整图标大小 */
}
.el-input__prefix {
	display: flex;
	align-items: center;
}
.el-input__inner {
	height: 50px;
	border-radius: 6px;
	opacity: 1;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 22px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
	background: rgba(0, 0, 0, 0);
	border: 1px solid #bfbfbf;
}

.el-textarea__inner {
	height: 100% !important;
	border-radius: 6px;
	opacity: 1;

	background: #ffffff;

	border: 1px solid #bfbfbf;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
	width: 100%;
}
.el-input__icon {
	line-height: 50px;
}
.el-input--prefix .el-input__inner {
	padding-left: 40px;
}

.el-input--suffix .el-input__inner {
	padding-right: 10px;
}

el-textarea {
	height: 100%;
}
</style>
